import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Box, Flex, LoadingBox, Spinner } from '../../../ui';

import {
  fetchCustomer,
  stageAchieved,
  updateStage1Results,
  updateStage2Results,
  updateStage3Results,
  updateStage4Results,
} from '../../../store/actions/sessionActions';
import Highcharts from 'highcharts';
import variablePie from 'highcharts/modules/variable-pie';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import EnvironmentalHealthResults from '../components/Scores/EnvironmentalHealthResults';
import {
  defaultLargeVerticalGap,
  defaultVerticalGap,
} from '../../../constants';
import StageStepper from '../components/Scores/StageStepper';
import UnlockLifestyleAndBiologicalResults from '../components/Scores/UnlockLifestyleAndBiologicalResults';
import StatusAlerts from '../components/Scores/StatusAlerts';
import HighchartsReact from 'highcharts-react-official';
import BiologicalHealthResults from '../components/Scores/BiologicalHealthResults';
import TotalHealthScore from '../components/Scores/TotalHealthScore';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SectionHeader, SectionTitle } from '../../../components/Text';

const mock = process.env.MOCK_SOURCES === 'true' || false;
const baseURL = process.env.API_SOURCE_HOST || 'https://api.getmnly.com/';
const klaviyoFormId = 'VnVVvb';
const klaviyoApiKey = process.env.KLAVIYO_API_KEY || 'Srdwct';

// @ts-ignore
if (!String.prototype.format) {
  // @ts-ignore
  String.prototype.format = function () {
    var args = arguments;
    return this.replace(/{(\d+)}/g, function (match, number) {
      return typeof args[number] != 'undefined' ? args[number] : match;
    });
  };
}

const getCustomer = async (email: string, jwt: string) => {
  try {
    const url = `${baseURL}customer${mock ? '.json' : '/' + email}`;
    const { data } = await axios.get(url,
      {
        headers: { Authorization: jwt },
      }
    );
    return data;
  } catch (e) {
    console.error(e);
  }
};

const ScoresPage = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const klaviyoRef = useRef<HTMLDivElement>(null);

  const stages = useSelector(state => {
    //@ts-ignore
    return state.session.stages;
  });

  const { customer, user } = useSelector(state => {
    //@ts-ignore
    return state.session;
  });

  const enviroChartRef = useRef<HighchartsReact.RefObject>(null);
  const bioChartRef = useRef<HighchartsReact.RefObject>(null);

  const kitId = searchParams.get('kitid');
  variablePie(Highcharts);

  const [loading, setLoading] = useState(true);
  const [registeringKit, setRegisteringKit] = useState(!!kitId);

  const [subscriptionFormLoaded, setSubscriptionFormLoaded] = useState(false);
  const [stage2State, setStage2State] = useState('Received By Lab');
  const [stage3State, setStage3State] = useState('Shipped To Lab');
  const [planData, setPlanData] = useState<any>(null);

  /*--- Register kit ---*/
  useEffect(() => {
    if (!loading && user?.token && kitId && planData?.lab_test?.id) {
      setRegisteringKit(true);
      console.log(kitId);
      axios
        .put(
          `${baseURL}lab/${planData.lab_test.id}/register`,
          {
            kit_id: kitId,
          },
          { headers: { Authorization: user.token } }
        )
        .then(res => {
          if (res.status === 204) {
            navigate('/instructions');
            setRegisteringKit(false);
          }
        })
        .catch(e => {
          toast.error('Error registering your kit, please contact support', {
            autoClose: 5000,
            closeOnClick: true,
            draggable: true,
            hideProgressBar: false,
            pauseOnHover: true,
            position: 'top-right',
            progress: undefined,
            theme: 'dark',
          });
          setRegisteringKit(false);
        });
    }
  }, [kitId, planData, loading]);

  const refreshStages = async () => {
    try {
      let url = `${baseURL}plan${mock ? '.json' : ''}`;
      const { data } = await axios.get(url, {
        headers: { Authorization: user?.token },
      });
      const labTest = data[0]?.lab_test;
      setPlanData(data[0]);
      let lifestyleResults = data[0]?.results; // could be{}
      if(lifestyleResults) {
        if (!lifestyleResults.hasOwnProperty('biomarker_output') && !lifestyleResults.hasOwnProperty('lifestyle_questions_output') && data[0].survey_response.finished) {

          let attempts = 0; // Add a limit to prevent infinite loop
          const maxAttempts = 2; // Prevent unnecessary API calls

          while (!lifestyleResults.hasOwnProperty('biomarker_output') && !lifestyleResults.hasOwnProperty('lifestyle_questions_output')) {

            if (attempts >= maxAttempts) {
              console.warn("Max attempts reached to gather lifestyleResults",lifestyleResults);
              break; // Stop if the API isn't returning expected results
            }

            const { data } = await axios.get(
              `${baseURL}plan${mock ? '.json' : ''}`,
              {
                headers: { Authorization: user?.token },
              }
            );
            if (lifestyleResults) {
              lifestyleResults = data[0].results;
            }

            attempts++; // Increase attempt count

            const sleep = (ms: number) => new Promise(r => setTimeout(r, ms));
            await sleep(500);
          }
        }
        dispatch(updateStage1Results(lifestyleResults));
      }

      if (labTest) {
        if (!labTest.kit_status || labTest.kit_status === null) {
          dispatch(updateStage2Results(labTest));
          setStage2State('Order Created by MNLY');
          return;
        }
        if (
          labTest.kit_status === 'Shipped To Patient' ||
          labTest.kit_status === 'Delivered To Patient'
        ) {
          setStage2State(labTest.kit_status);
          dispatch(updateStage2Results(labTest));
          return;
          // user is in stage 1
        }
        if (
          labTest.kit_status === 'Shipped To Lab' ||
          labTest.kit_status === 'Delivered To Lab'
        ) {
          dispatch(updateStage2Results(labTest));
          setStage2State(labTest.kit_status);
          dispatch(stageAchieved(2));

          return;
          // user is in stage 2
        }

        if (
          labTest.kit_status === 'Received By Lab' ||
          labTest.kit_status === 'Partially Resulted By Lab' ||
          (labTest.kit_status === 'Fully Resulted By Lab' &&
            !Array.isArray(labTest.results))
        ) {
          dispatch(updateStage2Results(labTest));
          dispatch(stageAchieved(2));
          dispatch(updateStage3Results(labTest));
          dispatch(stageAchieved(3));
          return;
          // user is in stage 3
        }

        if (
          labTest.kit_status === 'Fully Resulted By Lab' &&
          Array.isArray(labTest.results)
        ) {
          dispatch(updateStage2Results(labTest));
          dispatch(stageAchieved(2));
          dispatch(updateStage3Results(labTest));
          dispatch(stageAchieved(3));
          dispatch(updateStage4Results(lifestyleResults));
          dispatch(stageAchieved(4));
          dispatch(stageAchieved(5));
          // user has completed the flow
          return;
        }
      } else {
        setStage2State('Order Created by MNLY');
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (user && customer?.id) {
      refreshStages().then(() => {
        setLoading(false);
      });
    }
  }, [customer?.id, user]);

  useEffect(() => {
    if (user && customer && !customer?.id) {
      getCustomer(user.email, user.token).then(customer => {
        dispatch(fetchCustomer(customer));
      });
    }
  }, []);

  useEffect(() => {
    if (klaviyoRef!.current!.innerHTML === '') {
      const script = document.createElement('script');
      script.setAttribute(
        'src',
        `https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${klaviyoApiKey}`
      );

      for (let i = 0; i < 10; i++) {
        const div = document.createElement('div');
        div.setAttribute('class', `klaviyo-form-${klaviyoFormId}`);

        klaviyoRef!.current!.appendChild(script);
        klaviyoRef!.current!.appendChild(div);
      }
      setSubscriptionFormLoaded(true);

      if (klaviyoRef?.current) {
        klaviyoRef.current.style.display = 'none';
      }
    }
  }, [subscriptionFormLoaded]);

  let content;

  const allAchieved = stages.stage4?.achieved && stages.stage5?.achieved;
  const getStageForStatus = () => {
    const stagesKeys = Object.keys(stages);
    for (let i = 0; i < 3; i++) {
      if (stages[stagesKeys[i]] && !stages[stagesKeys[i]].achieved)
        return stages[stagesKeys[i]];
    }

    return stages.stage3;
  };

  if (registeringKit) {
    content = (
      <Flex
        flexDirection="column"
        alignItems="center"
        width="100%"
        gap={defaultVerticalGap}
      >
        <SectionTitle>Registering Kit...</SectionTitle>
        <Spinner color="gray.500" emptyColor="gray.200" />
      </Flex>
    );
  } else if (loading) {
    content = (
      <Box mb="1rem">
        <LoadingBox />
      </Box>
    );
  } else {
    content = (
      <>
        <StageStepper
          stage2State={stage2State}
          setStage2State={setStage2State}
          stage3State={stage3State}
          setStage3State={setStage3State}
        />
        {!allAchieved && <StatusAlerts nextStage={getStageForStatus()} />}
        {stages.stage1.achieved && (
          <EnvironmentalHealthResults chartComponent={enviroChartRef} />
        )}
        {stages.stage3.achieved && (
          <BiologicalHealthResults chartComponent={bioChartRef} />
        )}

        {!stages.stage3.achieved && (
          <UnlockLifestyleAndBiologicalResults
            lifestyleSurveyComplete={stages.stage1.achieved}
            biologicalComplete={stages.stage2.achieved}
          />
        )}

        {stages.stage4.achieved && stages.stage5.achieved && (
          <TotalHealthScore />
        )}
      </>
    );
  }

  return (
    <>
      <Flex flexDirection="column" gap={defaultLargeVerticalGap}>
        {content}

        <div
          ref={klaviyoRef}
          id={`klaviyo-form-${klaviyoFormId}-container`}
        ></div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </Flex>
    </>
  );
};

function mapStateToProps(state: { session: any }) {
  return {
    customer: state.session.customer,
    stages: state.session.stages,
    user: state.session.user,
  };
}

export default connect(mapStateToProps, {})(ScoresPage);
