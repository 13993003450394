import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Spinner,
  Text,
} from '../../ui';
import ActiveQuiz from './ActiveQuiz/ActiveQuiz';
import FinishedQuiz from './FinishedQuiz/FinishedQuiz';
import { fetchQuizById } from '../../store/actions/quizActions';
import {
  prevAnswerClick,
  quizAnswerClick,
  saveAnswerClick,
  quizSetCurrentQuestion,
} from '../../store/actions/sessionActions';
import { Box, Input, LoadingBox } from '../../ui';

class Quiz extends Component {
  constructor(props) {
    super(props);
    this.state = {
      birthDate: '',
      birthDateIsInvalid: false,
      loading: false,
    };
    this.validateBirthDateForm = this.validateBirthDateForm.bind(this);
  }

  async componentDidMount() {
    if (!this.props.session?.user?.id) {
      return null;
    }

    //TODO: Dramil to check, why DataPrime team hardcoded the survey id?
    let survey_id = '57254625-dd3c-4795-b19d-3fffe40163c5';
    if(this.props && this.props.session && this.props.session.quiz && this.props.session.quiz.survey_id) {
      survey_id = this.props.session.quiz.survey_id;
    }
    await this.props.fetchQuizById(survey_id);
    //TODO: Rate your level of mood throughout the day has no options

    const currentQuizQuestion = Object.entries(
      //@ts-ignore
      this.props.quiz.quiz.questions
    )
      //@ts-ignore
      //.filter(key => key[1].factorId !== 'NA')
      .map(entry => {
        return entry[1];
      })[0];

    this.props.quizSetCurrentQuestion(currentQuizQuestion);
  }

  onPrevAnswerClickHandler = () => {
    this.props.prevAnswerClick();
  }

  onAnswerClickHandler = answerId => {
    this.props.quizAnswerClick(answerId);
  };

  onSaveClickHandler = async () => {
    this.props.saveAnswerClick();
  }

  validateBirthDateForm = () => {
    const birthDateFieldWrongFormat: boolean =
      (!String(this.state.birthDate)
        .toLowerCase()
        .match(
          /^(19|20)\d{2}-(0[1-9]|1[0,1,2])(\/|-)(0[1-9]|[12][0-9]|3[01])$/
        ) as unknown as boolean) && this.state.birthDate !== undefined;

    if (birthDateFieldWrongFormat) {
      this.setState({ birthDateIsInvalid: true });
      return false;
    }

    return true;
  };

  render() {
    let content;

    if (
      this.props.quiz.quiz.questions &&
      !this.props.session?.customer?.birthdate
    ) {
      content = (
        <Box>
          <Text
            color="#E3DBD3"
            fontFamily="DIN-Medium"
            fontSize="18px"
            fontStyle="normal"
            lineHeight="130%"
            maxW="23rem"
            mt="3rem"
            mb="2rem"
            mx="auto"
            textAlign="center"
          >
            *We need to know your date of birth to make your personalized
            supplements*
          </Text>
          <FormControl
            isInvalid={this.state.birthDateIsInvalid}
            textAlign="center"
          >
            <Box textAlign="center">
              <Input
                _active={{ borderColor: '#1c242c' }}
                _focus={{ borderColor: '#1c242c' }}
                _hover={{ borderColor: '#1c242c' }}
                _selected={{ borderColor: '#1c242c' }}
                _invalid={{ borderColor: '#1c242c' }}
                borderColor="#1c242c"
                borderWidth=".2rem"
                borderRadius="0"
                filter="invert(1)"
                colorScheme="dark"
                value={this.state.birthDate}
                type="date"
                width="11rem"
                onChange={e => {
                  if (this.state.birthDateIsInvalid) {
                    this.setState({ birthDateIsInvalid: false });
                  }
                  this.setState({ birthDate: e.target.value });
                }}
              />
              {!this.state.birthDateIsInvalid ? (
                <FormHelperText />
              ) : this.state.birthDate === '' ? (
                <FormErrorMessage
                  width="21.25rem"
                  mb="0"
                  mt="1rem"
                  mx="auto"
                  justifyContent="center"
                >
                  Date is required.
                </FormErrorMessage>
              ) : (
                <FormErrorMessage
                  width="21.25rem"
                  mt="1rem"
                  mx="auto"
                  justifyContent="center"
                >
                  Date format is not valid.
                </FormErrorMessage>
              )}
            </Box>

            <Button
              _hover={{
                textDecoration: 'none',
                bg: '#A0856A',
              }}
              bg="#685848"
              borderRadius="0"
              color="#E3DBD3"
              fontFamily="DIN-Medium"
              fontSize=".85rem"
              fontWeight="medium"
              mt="2rem"
              w="8rem"
              onClick={() => {
                if (!this.validateBirthDateForm()) {
                  return;
                }
                this.setState({ loading: true });

                this.props.onDateOfBirthInput(this.state.birthDate);
              }}
              textTransform="uppercase"
            >
              {this.state.loading ? (
                <Box textAlign="center" pt=".4rem">
                  <Spinner color="gray.500" emptyColor="gray.200" />
                </Box>
              ) : (
                <>get started</>
              )}
            </Button>
          </FormControl>
        </Box>
      );
    } else
      content = (
        <div style={{ background: '#141414' }}>
          <div>
            {this.props.isLoading ||
            !this.props.quiz.quiz.questions ||
            !this.props.session.quiz.currentQuizQuestion ||
            (this.props.session.quiz.quizSubmitted &&
              !this.props.session.quiz.isQuizFinished) ? (
              <Box mt="4rem">
                <LoadingBox />
              </Box>
            ) : this.props.session.quiz.isQuizFinished ? (
              <FinishedQuiz />
            ) : (
              <ActiveQuiz
                answers={
                  this.props.session.quiz.currentQuizQuestion?.possible ?? []
                }
                // answerState={this.props.session.quiz.answerState}
                onPrevAnswerClick={this.onPrevAnswerClickHandler}
                onAnswerClick={this.onAnswerClickHandler}
                onSaveClick={this.onSaveClickHandler}
                question={this.props.session.quiz.currentQuizQuestion.question}
                questionFactor={
                  this.props.session.quiz.currentQuizQuestion.factorId
                }
                quizLength={
                  this.props.quiz?.quiz
                    ? Object.keys(this.props.quiz.quiz?.questions).length
                    : 1
                }
                questionNumber={
                  this.props.session.quiz.activeQuestionNumber + 1
                }
                questionType={this.props.session.quiz.currentQuizQuestion.type}
                questionResult={this.props.session.continue ?
                  (this.props.session.quiz.answers[this.props.session.quiz.currentQuizQuestion.id] ?
                  this.props.session.quiz.answers[this.props.session.quiz.currentQuizQuestion.id] :
                  this.props.session.quiz.results[this.props.session.quiz.currentQuizQuestion.id] ?
                  this.props.session.quiz.results[this.props.session.quiz.currentQuizQuestion.id] :
                  null) :
                  (this.props.session.quiz.results[this.props.session.quiz.currentQuizQuestion.id] ?
                  this.props.session.quiz.results[this.props.session.quiz.currentQuizQuestion.id] :
                  null)
                }
              />
            )}
          </div>
        </div>
      );
    return content;
  }
}

function mapStateToProps(state) {
  return {
    session: state.session,
    isLoading: state.quiz.isLoading,
    quiz: state.quiz,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchQuizById: (quizId: string) => dispatch(fetchQuizById(quizId)),
    prevAnswerClick: () => dispatch(prevAnswerClick()),
    quizAnswerClick: (answerId: any) => dispatch(quizAnswerClick(answerId)),
    saveAnswerClick: () => dispatch(saveAnswerClick()),
    quizSetCurrentQuestion: (question: string) =>
      dispatch(quizSetCurrentQuestion(question)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Quiz);
