import React, { useContext } from 'react';
import { AppState } from '../../tabs/Scores'
import Highcharts from 'highcharts';

export const getBiologicalHealthOptions = (
  results: any,
  prevResults: any,
  origResults: any,
): Highcharts.Options => {
  const series = [
    {
      name: "Original Results",
      data: [
          origResults?.biomarker_output?.biomarkers_categories_output?.cognitive
          .value,
          origResults?.biomarker_output?.biomarkers_categories_output?.hormonal
          .value,
          origResults?.biomarker_output?.biomarkers_categories_output?.longevity
          .value,
          origResults?.biomarker_output?.biomarkers_categories_output?.adrenal
          .value,
          origResults?.biomarker_output?.biomarkers_categories_output?.immune
          .value,
          origResults?.biomarker_output?.biomarkers_categories_output?.cardiometabolic
          .value,
          origResults?.biomarker_output?.biomarkers_categories_output?.gastrointestinal
          .value
      ]
    }, {
      name: "Previous Results",
      data: [
        prevResults?.biomarker_output?.biomarkers_categories_output?.cognitive
        .value,
        prevResults?.biomarker_output?.biomarkers_categories_output?.hormonal
        .value,
        prevResults?.biomarker_output?.biomarkers_categories_output?.longevity
        .value,
        prevResults?.biomarker_output?.biomarkers_categories_output?.adrenal
        .value,
        prevResults?.biomarker_output?.biomarkers_categories_output?.immune
        .value,
        prevResults?.biomarker_output?.biomarkers_categories_output?.cardiometabolic
        .value,
        prevResults?.biomarker_output?.biomarkers_categories_output?.gastrointestinal
        .value
      ]
    },{
      name: "Current Results",
      data: [
          results?.biomarker_output?.biomarkers_categories_output?.cognitive
          .value,
          results?.biomarker_output?.biomarkers_categories_output?.hormonal
          .value,
          results?.biomarker_output?.biomarkers_categories_output?.longevity
          .value,
          results?.biomarker_output?.biomarkers_categories_output?.adrenal
          .value,
          results?.biomarker_output?.biomarkers_categories_output?.immune
          .value,
          results?.biomarker_output?.biomarkers_categories_output?.cardiometabolic
          .value,
          results?.biomarker_output?.biomarkers_categories_output?.gastrointestinal
          .value
      ],
      pointPlacement: 'on'
    }
  ]

  const validSeries = series.filter(serie =>
    serie.data.every(score => score !== null && score !== undefined)
  );

  return {
chart: {
  polar: true,
  type: 'area',
  backgroundColor: '#141414',
  style: {
    fontFamily: 'DIN-Medium',
    cursor: 'pointer',
  },
  height: '400px',
  margin: 0,
},
title: {
  text: '',
},
pane: {
  size: '90%'
},
xAxis:{
  categories: ['Cognitive', 'Hormonal', 'Longevity', 'Adrenal', 'Immune', 'Cardiometabolic', 'Gastrointestinal'],
  tickmarkPlacement: 'on',
  lineWidth: 1,
  labels: {
    style: {
      color: '#E3DBD3',
      fontFamily: 'DIN-Medium',
      fontSize: '13px',
    },
  }
},
tooltip: {
  backgroundColor: '#141414',
  borderColor: '#E3DBD3',
  style: { fontFamily: 'DIN-regular', fontSize: '18px', width: 420, color: '#E3DBD3' },
  outside: true,
},
legend: {
  align: 'left',
  verticalAlign: 'bottom',
  layout: 'vertical',
  margin: 30,
  floating: false,
  itemStyle:{
    color: '#E3DBD3',
    fontFamily: 'DIN-regular',
    fontSize: '13px',
    fontWeight: 'normal',
  },
  itemHoverStyle: {
    color: '#9b7a58',
  }
},
series: validSeries,

credits: {
  enabled: false
}
  };
};


export const getEnvironmentalHealthChartOptions = (
  results: any,
  prevResults: any,
  origResults: any,
): Highcharts.Options => {
  // Prepare series based on available data
  const series = [
    {
      name: "Original Results",
      data: [
        origResults?.lifestyle_output?.lifestyle_categories_output?.focus?.score,
        origResults?.lifestyle_output?.lifestyle_categories_output?.confidence?.score,
        origResults?.lifestyle_output?.lifestyle_categories_output?.stamina?.score,
        origResults?.lifestyle_output?.lifestyle_categories_output?.libido?.score,
        origResults?.lifestyle_output?.lifestyle_categories_output?.sleep?.score,
        origResults?.lifestyle_output?.lifestyle_categories_output?.mood?.score,
        origResults?.lifestyle_output?.lifestyle_categories_output?.recovery?.score
      ]
    },
    {
      name: "Previous Results",
      data: [
        prevResults?.lifestyle_output?.lifestyle_categories_output?.focus?.score,
        prevResults?.lifestyle_output?.lifestyle_categories_output?.confidence?.score,
        prevResults?.lifestyle_output?.lifestyle_categories_output?.stamina?.score,
        prevResults?.lifestyle_output?.lifestyle_categories_output?.libido?.score,
        prevResults?.lifestyle_output?.lifestyle_categories_output?.sleep?.score,
        prevResults?.lifestyle_output?.lifestyle_categories_output?.mood?.score,
        prevResults?.lifestyle_output?.lifestyle_categories_output?.recovery?.score
      ]
    },
    {
      name: "Current Results",
      data: [
        results?.lifestyle_output?.lifestyle_categories_output?.focus?.score,
        results?.lifestyle_output?.lifestyle_categories_output?.confidence?.score,
        results?.lifestyle_output?.lifestyle_categories_output?.stamina?.score,
        results?.lifestyle_output?.lifestyle_categories_output?.libido?.score,
        results?.lifestyle_output?.lifestyle_categories_output?.sleep?.score,
        results?.lifestyle_output?.lifestyle_categories_output?.mood?.score,
        results?.lifestyle_output?.lifestyle_categories_output?.recovery?.score
      ],
      pointPlacement: 'on'
    }
  ];

  // Filter out series with null data
  const validSeries = series.filter(serie =>
    serie.data.every(score => score !== null && score !== undefined)
  );

  return {
    chart: {
      polar: true,
      type: 'area',
      backgroundColor: '#141414',
      style: {
        fontFamily: 'DIN-Medium',
        cursor: 'pointer',
      },
      height: '400px',
      margin: 0,
    },
    title: {
      text: '',
    },
    pane: {
      size: '90%'
    },
    xAxis: {
      categories: ['Focus', 'Confidence', 'Stamina', 'Libido', 'Sleep', 'Mood', 'Recovery'],
      tickmarkPlacement: 'on',
      lineWidth: 1,
      labels: {
        style: {
          color: '#E3DBD3',
          fontFamily: 'DIN-Medium',
          fontSize: '13px',
        },
      }
    },
    tooltip: {
      backgroundColor: '#141414',
      borderColor: '#E3DBD3',
      style: {
        fontFamily: 'DIN-regular',
        fontSize: '18px',
        width: 420,
        color: '#E3DBD3'
      },
      outside: true,
    },
    legend: {
      align: 'left',
      verticalAlign: 'bottom',
      layout: 'vertical',
      margin: 30,
      floating: false,
      itemStyle: {
        color: '#E3DBD3',
        fontFamily: 'DIN-regular',
        fontSize: '13px',
        fontWeight: 'normal',
      },
      itemHoverStyle: {
        color: '#9b7a58',
      }
    },
    series: validSeries,
    credits: {
      enabled: false
    }
  };
};


export const getBiomarkerScoresChartOptions = (
  currentTest: any,
  prevTest: any,
  unit: any,
): Highcharts.Options => {

  // let categories = [];
  // let data = [];

  // // Conditionally populate the categories and data arrays
  // if (origTest && origTest.length !== 0) {
  //   categories = ['Original Result', 'Previous Result', 'Current Result'];
  //   data = [parseFloat(origTest), parseFloat(prevTest), parseFloat(currentTest)];
  // } else {
  //   categories = ['Previous Result', 'Current Result'];
  //   data = [parseFloat(prevTest), parseFloat(currentTest)];
  // }

  // // Filter out NaN values to avoid issues in the chart
  // data = data.map(value => (isNaN(value) ? null : value));

  return {
  chart: {
    backgroundColor: '#141414',
    style: {
      fontFamily: 'DIN-Medium',
      cursor: 'pointer',
    },
  },
  title: {
    text: 'Results Over Time',
    verticalAlign:'top',
    align:'center',
    style: {
      color: '#bf936b',
      fontFamily: 'DIN-Medium',
      fontSize: '20px',
    },
  },
  xAxis: {
    categories: ["Previous Results", "Current Results"],
    tickColor: '#E3DBD3',
    labels: {
      style: {
        color: '#E3DBD3',
        fontFamily: 'DIN-Medium',
        fontSize: '13px',
      },
    }
  },
  yAxis: {
    title: {
      text: `Unit: ${unit}`,
      style: {
        color: '#bf936b',
        fontFamily: 'DIN-Medium',
        fontSize: '13px',
        rotation: 270, // Rotate the y-axis title by 270 degrees (counterclockwise)
        textAlign: 'right', // Align the text to the right
        x: -20, // Adjust the horizontal spacing between the title and the axis
      }
    },
    tickColor: '#E3DBD3',
    gridLineColor: '#E3DBD3',
  },
  plotOptions: {
    series: {
      marker: {
        symbol: 'circle'
      }
    }
  },
  legend: {
    align: 'center',
    verticalAlign: 'bottom',
    layout: 'vertical',
    margin: 30,
    floating: false,
    itemStyle:{
      color: '#bf936b',
      fontFamily: 'DIN-regular',
      fontSize: '13px',
      fontWeight: 'normal',
    },
    itemHoverStyle: {
      color: '#9b7a58',
    }
  },
  tooltip: {
    backgroundColor: '#141414',
    borderColor: '#E3DBD3',
    style: { fontFamily: 'DIN-regular', fontSize: '18px', width: 420, color: '#E3DBD3' },
    outside: true,
  },
  series: [{
    name: "Results",
    data: [parseFloat(prevTest), parseFloat(currentTest)],
}],
credits: {
  enabled: false
}
}};
